import $ from 'jquery';

export default () => {
    // Scroll to
    $(document).on('click', 'a[href]:not([href^="mailto\\:"], [href$="\\#"])', function (event) {
        function isValidSelector(selector) {
            if (typeof selector !== 'string') {
                return false;
            }
            try {
                var $element = $(selector);
            } catch (error) {
                return false;
            }
            return true;
        }

        if (isValidSelector($(this).attr('href'))) {
            if ($($.attr(this, 'href')).length && $.attr(this, 'href') !== '#wlymmenu' && $.attr(this, 'href') !== '#') {
                event.preventDefault();
                $('html, body').animate(
                    {
                        scrollTop: $($.attr(this, 'href')).offset().top - $('header').height(),
                    },
                    1000
                );
            }
        }
    });

    // Navigation Touch Geräte
    $('#headerNavbar ul.navbar-nav > li.nav-menu > a').on('touchend', function (e) {
        if (!$(this).parent().hasClass('hovered')) {
            e.preventDefault();
            $(this).parent().toggleClass('hovered');
            $(this).parent().siblings().removeClass('hovered');
        }
    });

    const hoveredItems = $('[class*="hovered"]');

    hoveredItems.each(function () {
        $(this).removeClass('.hovered');
    });
};
