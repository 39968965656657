import ImagesLoaded from 'imagesloaded';
import Masonry from 'masonry-layout';

const wrappers = document.querySelectorAll('.wly-masonry');

function initMasonry(el, options) {
  return new Masonry(el, {
    itemSelector: '.wly-masonry-item',
    ...options,
  });
}

function rerender(masonry) {
  if (!masonry) {
    return;
  }

  masonry.layout();
}

for (let node of wrappers) {
  let masonry = null;

  ImagesLoaded(node, () => {
    masonry = initMasonry(node, eval('(' + node.dataset.masonry + ')') ?? {});
  });

  document.addEventListener('lazyloaded', function (e) {
    rerender(masonry);
  });
}
