import { AlpineComponent } from '@/js/alpine/helper';

export class ScrollDirectionComponent extends AlpineComponent {
    public lastScrollPosition: number = 0;
    public lastDirectionChangePosition: number = 0;
    public currentScrollPosition: number = 0;

    public actualScrollDirection = 0;
    public computedScrollDirection = 0;

    init() {
        window.addEventListener('scroll', (e) => {
            this.lastScrollPosition = this.currentScrollPosition;
            this.currentScrollPosition = window.scrollY;

            const actualScrollDirection = this.currentScrollPosition > this.lastScrollPosition ? 1 : this.currentScrollPosition < this.lastScrollPosition ? -1 : 0;

            if (this.actualScrollDirection !== actualScrollDirection) {
                this.lastDirectionChangePosition = this.currentScrollPosition;
                this.actualScrollDirection = actualScrollDirection;
            }

            if (Math.abs(this.currentScrollPosition - this.lastDirectionChangePosition) < this.minScrollY()) {
                this.computedScrollDirection = 0;
            } else {
                this.computedScrollDirection = this.actualScrollDirection;
            }
        });
    }

    minScrollY() {
        return window.outerHeight / 2;
    }

    classNames() {
        return {
            'scroll-direction-down': this.computedScrollDirection === 1,
            'scroll-direction-neutral': this.computedScrollDirection === 0 && window.scrollY > this.minScrollY(),
            'scroll-direction-up': this.computedScrollDirection === -1,
        };
    }
}
