// @ts-ignore
import baguetteBox from 'baguettebox.js';
import Swiper, { Autoplay, Navigation, Pagination } from 'swiper';

export default () => {
    var wlySwiper = new Swiper('.swiper-container-wly', {
        modules: [Navigation, Pagination, Autoplay],
        // Optional parameters
        direction: 'horizontal',
        loop: true,
        speed: 1400,
        spaceBetween: 30,
        autoplay: {
            delay: 7000,
        },
        grabCursor: true,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: true
        }
    });

    new Swiper('.featured-slider', {
        modules: [Autoplay],
        direction: 'horizontal',
        loop: true,
        speed: 1000,
        spaceBetween: 0,
        autoplay: {
            delay: 6000,
        },
        grabCursor: false,
    });
};
