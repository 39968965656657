import { onReady } from './ready.ts';
import { InitializeForms } from './form.js';
import { updateActiveItem } from './navigation.ts';
import Accordion from './accordion.ts';
import Autosize from './autosize.ts';
import Swiper from './swiper.ts';
import lazySizes from 'lazysizes';
import Menu from './mmenu';
import Teich from './teich';

import Isotope from './isotope';
import './baguette-box';
import './masonry';

onReady(() => {
  Menu();
  updateActiveItem();
  lazySizes.init();
  Accordion();
  Autosize();
  Swiper();
  InitializeForms();
  Isotope();
  Teich();
});
