import {registerCustomElements} from "./custom-elements";

import makeVue from './vue'

registerCustomElements();

for (const node of (document.querySelectorAll('[data-vue-instance]') ?? [])) {
    makeVue(node);
}

for (const node of (document.querySelectorAll('[data-vue-instance-replace]') ?? [])) {
    makeVue(node, true);
}